window.require = require
window.jQuery = window.$ = require('jquery')


var tri = require('tripartite')
var tripartite = tri


var expandCollapse = require('../node_modules/ei-collapse-boxes-1')
expandCollapse('.careguides', '.bg_faq_content_section')

$('.tribar').click(function(evt) {
	evt.preventDefault()
	$('.main-menu').addClass('open')
})



$('.is-mobile-phone').change(function(evt) {
	$(this).closest('form').find('button').prop('disabled', null)
})

