var $ = require('jquery')
var Uploader = require('./pic-upload')
var PicBrowser = require('./pic-browser')
var Dialog = require('ei-dialog')

var tri = require('tripartite').createBlank()
tri.parseTemplateScript(require('./image-in-input.tmpl'))
var templates = tri.templates

var statusAttr = 'data-image-in-input-status'

var addStylesIfNeeded = function() {
	if($('#image-in-input-styles').length == 0) {
		$('head').append('<style type="text/css" id="image-in-input-styles">' +
		templates['imageInInputStyles']() +
		'</style>')
	}
}


var imageInInput = function(inputControl, imagesSource, newImagePost, imagePrefix) {
	this.$inputControl = $(inputControl)
	this.imagesSource = imagesSource
	this.newImagePost = newImagePost
	this.imagePrefix = imagePrefix
}

function isTouchDevice() {
	var deviceAgent = navigator.userAgent.toLowerCase();
	var msGesture = window.navigator && window.navigator.msPointerEnabled && window.MSGesture;
	var hasTouchStart = ( "ontouchstart" in window );
	var touchSupport = msGesture || (navigator.msMaxTouchPoints > 0) || (window.DocumentTouch && document instanceof DocumentTouch);

	var isTouchDevice = touchSupport ||
	(deviceAgent.match(/(iphone|ipod|ipad)/) ||
	deviceAgent.match(/(android)/)  ||
	deviceAgent.match(/(iemobile)/) ||
	deviceAgent.match(/iphone/i) ||
	deviceAgent.match(/ipad/i) ||
	deviceAgent.match(/ipod/i) ||
	deviceAgent.match(/blackberry/i) ||
	deviceAgent.match(/bada/i));

	return isTouchDevice;
}

imageInInput.prototype.render = function() {
	if(this.$inputControl.attr(statusAttr) == 'processed') {
		return;
	}

	this.$inputControl.attr(statusAttr, 'processed')


	var $next = this.$inputControl.next()
	if($next.hasClass('image-browser-replacement')) {
		$next.remove()
	}


	addStylesIfNeeded()
	var self = this
	this.$widget = $(templates['imageReplacementWidget']())
	this.$inputControl.after(this.$widget)
	this.$inputControl.attr('type', 'hidden')




	this.$widget.find('.change').click(function(evt) {
		evt.preventDefault()
		self.browser = new PicBrowser(self.imagesSource(), function(chosen) {
			var fin = new Image()
			var $final = self.$widget.find('.image-holder').html(fin)
			fin.src = chosen.fullSize
			self.diag.close()
			self.$inputControl.val(chosen.fullSize)
			if(self.onComplete) {
				self.onComplete(self.imagePrefix + self.uploader.getImageName())
			}
			self.uploader.cleanup()
		})
		
		self.diag = new Dialog({
			title: 'Choose Image',
			body: function(container) { 
				$(container).append('<p>Choose an image or drag and drop a new image or paste an image (ctrl-v).</p>')
				var inputButtonText = "Choose a file"
				if(isTouchDevice()) {
					inputButtonText = "Take a picture"
				}
				$(container).append('<br/><label class="cameraButton">' + inputButtonText + ' <input type="file" class="take-picture" accept="image/*;capture=camera"><br/><br/>')
  				self.browser.render(container)
				return ''
			},
			on: {
				'.btn-ok': function() {
					var fin = new Image()
					var $final = self.$widget.find('.image-holder').html(fin)
					fin.src = self.uploader.getImageData()
					self.$inputControl.val(self.imagePrefix + self.uploader.getImageName())
					self.newImagePost(self.uploader.getImageData(), self.uploader.getImageName())
					if(self.onComplete) {
						self.onComplete(self.imagePrefix + self.uploader.getImageName())
					}
					self.uploader.cleanup()
				},
				'.btn-cancel': function() {
				}
			}
		})
		self.diag.open()

		var $diagBody = $('.dialog-frame .body')
		self.uploader = new Uploader($diagBody, $diagBody)
		self.uploader.render()
	})
	this.$widget.find('.clear').click(function(evt) {
		evt.preventDefault()
		self.$inputControl.val('')
		self.$widget.find('.image-holder img').remove()
	})
	
	var fin = new Image()
	var $final = self.$widget.find('.image-holder').html(fin)
	fin.src = this.$inputControl.val()
	
	return this
}

imageInInput.prototype.cleanup = function() {
	this.$inputControl.attr('type', 'text')
	this.$widget.remove()
	return this
}

module.exports = imageInInput
