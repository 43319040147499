window.require = require
window.jQuery = window.$ = require('jquery')

var tri = window.Tripartite = require('tripartite')


require('./pages')

window.CKEDITOR_BASEPATH = '/'
require('ckeditor')

// custom config
CKEDITOR.config.customConfig = '/ck-textarea-config.js'

require('webhandle-page-editor/app-client')
require('../node_modules/@dankolz/ei-people-groups-1/client-js/people-groups-client.js')
require('../node_modules/@dankolz/webhandle-jobs/client-js/jobs-client.js')
require('../node_modules/webhandle-calendar/client-js/calendar-app-client.js')
require('ei-slideshow-1/client-js/slideshow-client')


require('../node_modules/@dankolz/webhandle-news/client-js/app.js')

