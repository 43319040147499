var $ = require('jquery')

var tri = require('tripartite').createBlank()
tri.parseTemplateScript(require('./browser.tmpl'))

var templates = tri.templates

var addStylesIfNeeded = function() {
	if($('#pic-browser-styles').length == 0) {
		$('head').append('<style type="text/css" id="pic-browser-styles">' +
		templates['picBrowserStyles']() +
		'</style>')
	}
}

var PicBrowser = function(/*array*/ pictures, /*function*/ chosenCallback) {
	this.chosenCallback = chosenCallback
	this.renderObjs = []
	for(var i in pictures) {
		var location = pictures[i]
		if(typeof location == 'string') {
			var name = location.split('/').pop()
			var thumbnail
			var fileType
			if(name.toLowerCase().indexOf('.png') > -1 || name.toLowerCase().indexOf('.jpg') > -1 || name.toLowerCase().indexOf('.jpeg') > -1 || name.toLowerCase().indexOf('.gif') > -1) {
				thumbnail = location
				fileType = 'image'
			}
			else if(name.toLowerCase().indexOf('.') > -1) {
				thumbnail = '/page-editor/img/document.png'
				fileType = 'document'
			}
			else {
				thumbnail = '/page-editor/img/folder.png'
				fileType = 'folder'
			}

			this.renderObjs.push({
				thumbnail: thumbnail,
				fullSize: location,
				name: name,
				fileType: fileType
			})
		}
		else {
			this.renderObjs.push(location)
		}
	}
}

PicBrowser.prototype.render = function(container) {
	addStylesIfNeeded()
	$(container).append(templates['picBrowserHolder']({images: this.renderObjs}))
	
	var self = this
	$(container).on('click', '.pic-holder', function(evt) {
		evt.preventDefault()
		if(self.chosenCallback) {
			self.chosenCallback(self.renderObjs[$(evt.target).closest('.pic-holder').index()])
		}
	})
}

module.exports = PicBrowser